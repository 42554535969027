import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2797b97e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "PcList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, {
          class: "myRow",
          style: {"margin-right":"20px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("是否OA下单：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('oaFlag',el),
                  modelValue: _ctx.pageList.queryParam.oaFlag,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageList.queryParam.oaFlag) = $event)),
                  placeholder: "请选择是否OA下单",
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'oaFlag'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("OA下单时间：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.oaTime,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.oaTime) = $event)),
                  type: "daterange",
                  shortcuts: _ctx.utils.Tools.defDateRange(),
                  style: {"width":"100%"}
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, {
          class: "myRow",
          style: {"margin-right":"20px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("生产基地：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('scjd',el),
                  modelValue: _ctx.pageList.queryParam.scjd,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.scjd) = $event)),
                  placeholder: "请选择生产基地",
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'scjd'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_CODE",
          label: "排产单编号"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SJPCRQ",
          label: "实际排产日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_YJRKRQ",
          label: "预计入库日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SJRKRQ",
          label: "实际入库日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_KFYRQ",
          label: "可发运日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SCJD",
          label: "生产基地",
          width: "80"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_OA_FLAG",
          label: "OA下单",
          width: "60"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_OA_TIME",
          label: "OA下单时间"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}